.static {
	display: block;
}

.static-v1 {
	.panel-body {
		@media (min-width: 992px) {
			min-height: 250px;
		}
	}
}

.mob-center {
	@media (max-width:992px) {
		text-align: center;
	}

	img {
		display: inline-block;
	}

}

.col3-row {
	.col-md-4 {
		@media (min-width: 992px) {
			&:nth-child(3n+1) {
				clear: both;
			}
		}
	}
}

.inner-banner {
	background-image: url(RESOURCE/img/banner3.jpg);
	background-position: center 90%;
	background-repeat: no-repeat;
	background-size: cover;
	height: 390px;
	position: relative;

	@media (max-width:992px) {
		height: 320px;
	}


	.page-title {
		position: absolute;
		bottom: 70px;
		width: 100%;
		left: 0;
		z-index: 2;
		margin: 0;
		border-bottom: none;

		@media (max-width:992px) {
			bottom: 30px;
		}


		h1 {
			font-size: 42px;
			margin-bottom: 0;
			color: var(--white);
			text-transform: uppercase;

			@media (max-width:992px) {
				font-size: 30px;
			}
		}
	}
}

#accordion {
	.img_text_box {
		display: flex;

		strong {
			font-weight: bold;
			margin-left: 15px;
			font-size: 15px;
			display: inline-block;
		}
	}

	iframe {
		max-width: 100%;
	}

	img {
		&.pull-right {
			@media (max-width:480px) {
				float: none !important;
				margin-bottom: 15px;
			}
		}
	}

	ul {
		margin-left: 15px;

		li {
			font-size: 16px;
		}
	}

	.panel-body {
		h3 {
			margin-top: 0;
		}
	}

	.panel+.panel {
		margin-top: 15px;
	}

	.panel-heading {
		background-color: var(--primary);
		padding: 15px;

		.panel-title a:before {
			content: "\f055";
			font-family: Font Awesome\ 5 Pro;
			left: 0;
			top: 0;
			position: absolute;
		}

		.panel-title a {
			display: flex;
			width: 100%;
			color: #fff;
			text-decoration: none;
			position: relative;
			word-break: break-all;
			padding-left: 40px;
			font-size: 18px;
			margin: 0;
			line-height: 22px;
		}

		.panel-title a[aria-expanded=true]:before {
			content: "\f056";
		}
	}
}


.teams-bl {
	max-width: 1040px;
	margin: 0 auto;

	@media (min-width:768px) {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
	}

	img {
		width: 100%;
	}

	.team-bl {
		border: 1px solid #e8e8e8;

		.team-dt {

			border-top: none;
			padding: 20px 8px;
			text-align: center;
		}
	}
}


.contact-page {
	.control-label {
		text-align: left;
		padding-bottom: 5px;
	}

	.form-control {
		border: none;
		border-bottom: 2px solid #f2f2f2;
		text-transform: uppercase;
		margin-bottom: 30px;
		border-radius: 0;
		box-shadow: inherit;
		padding: 0;
	}

	label {
		color: var(--black);
		font-weight: 700;
		font-size: 14px;
		text-transform: uppercase;
		margin-bottom: 0;
		padding-bottom: 10px;
	}

	.checkbox {
		label {
			text-transform: inherit;
			font-weight: normal;
		}

		/*a {
			color: var(--black);
			font-size: 14px;
			position: relative;
			top: -2px;
		}

		.privacy-link {
			position: relative;
			top: -2px;
		}*/

	}

	.btn-go {
		box-shadow: 2px 1px 10px rgba(0, 0, 0, .2);
		font-size: 20px;
	}
}

.voucher-v1 {
	.row {
		justify-content: center;
		display: flex;

		.voucher-form {
			margin-bottom: 70px;

			.row {
				@media (max-width:767px) {
					display: inline-block;
					width: 100%;
				}

				.col-sm-12 {
					@media (max-width:767px) {
						padding: 0;
					}
				}
			}
		}
	}
}

.angebote-v1 {
	margin-top: var(--page-margin-top);

	.angebote-boxes-v1 {
		.blogcontent {
			margin-bottom: 30px;
		}

		.img-thumbnail {
			padding: 0;
			border: none;
			border-radius: 0;
		}

		.bl-img {
			img {
				height: 450px;
				object-fit: cover;
				width: 100%;

				@media (max-width: 1199px) {
					height: 300px;
				}

				@media (max-width: 767px) {
					height: 350px;
				}
			}
		}

		.btn.btn-primary {
			position: absolute;
			bottom: 60px;
			right: 30px;

			@media (max-width: 767px) {
				position: static;
			}
		}

		.lt-bl-cont {
			padding: 30px;
			background: #f4f4f4;
			padding-bottom: 90px;
			height: 335px;
			overflow: hidden;

			@media (max-width: 1199px) {
				height: 370px;
			}

			@media (max-width: 991px) {
				height: 475px;
			}

			@media (max-width: 767px) {
				height: auto;
				padding-bottom: 30px;
			}

			h4 {
				font-size: 22px;
			}

			p {}
		}
	}
}